import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import parse from "html-react-parser"

const FarsiArchive = ({ data }) => {
  const farsiPosts = data.allWpFarsi.nodes
  const archivePage = data.wpPage

  return (
    <Layout>
      <Seo
        title={archivePage.smartcrawlSeo?.title}
        description={archivePage.smartcrawlSeo?.metaDesc}
        ogTitle={archivePage.smartcrawlSeo?.opengraphTitle}
        ogDescription={archivePage.smartcrawlSeo?.opengraphDescription}
        twTitle={archivePage.smartcrawlSeo?.twitterTitle}
        twDescription={archivePage.smartcrawlSeo?.twitterDescription}
        featuredImage={archivePage.featuredImage?.node?.mediaItemUrl}
        ogImage={archivePage.smartcrawlSeo?.opengraphImage?.mediaItemUrl}
        twImage={archivePage.smartcrawlSeo?.twitterImage?.mediaItemUrl}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Organization",
              "url": "https://centerforinquiry.org/",
              "name": "Center for Inquiry ",
              "description": "The Center for Inquiry (CFI) is a 501(c)(3) charitable nonprofit organization dedicated to defending science and critical thinking in examining religion. CFI’s vision is a world in which evidence, science, and compassion—rather than superstition, pseudoscience, or prejudice—guide public policy.",
              "@id": "https://centerforinquiry.org/#organization/",
              "logo": "https://translationsproject.org/static/5b8e5d5a96fdd64b0bb44c7de1015d52/f2ee4/translations-project-logo-desktop-768x240.webp",
              "sameAs": [
                "https://www.facebook.com/centerforinquiry/",
                "https://twitter.com/center4inquiry"
              ],
              "subOrganization": [
                {
                  "@type": "Organization",
                  "@id": "https://translationsproject.org/#organization",
                  "name": "The Translations Project" 
                }
              ]
            }     
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": "Translations Project",
              "@id": "https://translationsproject.org/#website",
              "url": "https://translationsproject.org/",
              "isAccessibleForFree": true,
              "sourceOrganization": 
                {
                  "@type": "Organization",
                  "@id": "https://centerforinquiry.org/#organization",
                  "name": " Center for Inquiry" 
                }
            }     
          `}
        </script>
      </Helmet>
      <main
        id="main"
        className="site-main no-stretch dft-padding archive farsi"
        role="main"
      >
        <header className="entry-header">
          <h1 className="entry-title">{parse(archivePage.title)}</h1>
          <hr />
        </header>
        <h2 class="has-text-align-center">– کتابها –</h2>
        <div lang="fa" className="entry-content">
          {farsiPosts.map(farsi => {
            const title = farsi.title
            const categories = farsi.categories.nodes
            const featuredImage = {
              src:
                farsi.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData,
              alt: farsi.featuredImage?.node?.alt || ``,
            }
            return (
              <>
                {categories.map(category => {
                  return (
                    <>
                      {category.name === "Books" && (
                        <article
                          className="wp-block-columns mb-5 flex-row-reverse"
                          itemScope
                          itemType="http://schema.org/Article"
                        >
                          {featuredImage?.src && (
                            <div
                              className="wp-block-column is-vertically-aligned-center"
                              style={{ flexBasis: "25%" }}
                            >
                              <Link to={farsi.uri}>
                                <GatsbyImage
                                  loading="lazy"
                                  layout="fullWidth"
                                  placeholder="blurred"
                                  image={featuredImage.src}
                                  className="book-covers shadow"
                                  alt={featuredImage.alt}
                                  objectFit="cover"
                                  objectPosition="50% 50%"
                                  formats={"webp"}
                                />
                              </Link>
                            </div>
                          )}
                          <div
                            className="wp-block-column is-vertically-aligned-center"
                            style={{ flexBasis: "75%" }}
                          >
                            <h2 className="h5 has-text-align-right">
                              <Link to={farsi.uri} itemProp="url">
                                <span itemProp="headline">{parse(title)}</span>
                              </Link>
                            </h2>
                            <section itemProp="description">
                              {farsi.content && parse(farsi.content)}
                            </section>
                          </div>
                        </article>
                      )}
                    </>
                  )
                })}
              </>
            )
          })}
          <h2 class="has-text-align-center">- فیلم های -</h2>
          {farsiPosts.map(farsi => {
            const title = farsi.title
            const categories = farsi.categories.nodes
            const featuredImage = {
              src:
                farsi.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData,
              alt: farsi.featuredImage?.node?.alt || ``,
            }
            return (
              <>
                {categories.map(category => {
                  return (
                    <>
                      {category.name === "Videos" && (
                        <article
                          className="wp-block-columns mb-5 flex-row-reverse"
                          itemScope
                          itemType="http://schema.org/Article"
                        >
                          {featuredImage?.src && (
                            <div
                              className="wp-block-column is-vertically-aligned-center"
                              style={{ flexBasis: "25%" }}
                            >
                              <Link to={farsi.uri}>
                                <GatsbyImage
                                  loading="lazy"
                                  layout="fullWidth"
                                  placeholder="blurred"
                                  image={featuredImage.src}
                                  className="shadow"
                                  alt={featuredImage.alt}
                                  objectFit="cover"
                                  objectPosition="50% 50%"
                                  formats={"webp"}
                                />
                              </Link>
                            </div>
                          )}
                          <div
                            className="wp-block-column is-vertically-aligned-center"
                            style={{ flexBasis: "75%" }}
                          >
                            <h2 className="h5 has-text-align-right">
                              <Link to={farsi.uri} itemProp="url">
                                <span itemProp="headline">{parse(title)}</span>
                              </Link>
                            </h2>
                            <section itemProp="description">
                              {farsi.content && parse(farsi.content)}
                            </section>
                          </div>
                        </article>
                      )}
                    </>
                  )
                })}
              </>
            )
          })}
        </div>
      </main>
    </Layout>
  )
}

export default FarsiArchive

export const pageQuery = graphql`
  query WordPressFarsiArchive($offset: Int!, $postsPerPage: Int!) {
    wpPage(slug: { eq: "farsi" }) {
      title
      content
      databaseId
      uri
      slug
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }

    allWpFarsi(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        content
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
